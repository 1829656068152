
/*   *** Footer Section Styling Starts ***   */
.page-footer{
	width: 100%;
	padding: 20px 8%;
	margin-top: 20px;
	background-color: var(--primary-light-clr);
}
.page-footer .footer-contents{
	display: flex;
	justify-content: space-between;
	text-align-last: center;
}
.footer-contents a{
	text-decoration: none;
	font-size: 22px;
	font-weight: 700;
	color: var(--primary-clr);
	margin-right: 20px;
}
.footer-contents p{
	font-size: 16px;
	color: var(--text-clr);
}
.footer-contents p span{
	color: var(--primary-clr);
	font-weight: bold;
}
/*   *** Footer Section Styling Ends ***   */


@media (max-width: 550px) {
    	/* Footer Section */
	.page-footer .footer-contents{
		flex-direction: column;
	}
	.footer-contents a{
		margin-bottom: 10px;
	}
	.footer-contents p{
		margin-bottom: 10px;
	}
}