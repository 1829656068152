
/*   *** Contact Section Styling Starts ***   */
.contact-form{
	width: 100%;
    min-height: 100%;
	padding: 70px 8% 50px;
    /* text-align: center;
    align-items: center;
    justify-content: center; */
}
.contact-form .contact-row{
	width: 100%;
	display: flex;
	background-color: var(--bg-clr-one);
}
.contact-row .column-1{
	flex-basis: 40%;
	min-width: 320px;
	padding: 50px;
}
.contact-row .column-2{
	flex-basis: 60%;
}
.column-1 .contactTitle h2{
	position: relative;
	font-size: 36px;
	color: var(--primary-clr);
	display: inline-block;
	margin-bottom: 25px;
}
.column-1 .contactTitle h2::before{
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	top: 120%;
	left: 0px;
	background-color: var(--secondary-heading);
}
.column-1 .contactTitle h2::after{
	content: '';
	position: absolute;
	width: 40%;
	height: 3px;
	top: calc(120% - 1px);
	left: 0px;
	background-color: var(--primary-clr);
}
.column-1 .contactTitle p{
	font-size: 15px;
	color: var(--text-clr);
	letter-spacing: 1px;
	line-height: 1.2;
	padding-bottom: 20px;
}
.form-1 .main{
    display: flex;
    flex-direction: row;
}
.form-1 .inputGroup{
	position: relative;
	margin: 40px 5px;
    /* width: 50%; */
}
.form-1 .main .inputGroup {
    width: 50%;
}
.form-1 input{
	width: 100%;
	font-size: 18px;
	padding: 2px 0px;
	background-color: var(--bg-clr-one);
	color: var(--primary-clr);
	border: none;
	border-bottom: 1px solid var(--text-clr);
	outline: none;
}
.form-1 label,
.column-2 .form-2 label{
	position: absolute;
	left: 0;
	bottom: 4px;
	color: var(--text-clr);
	font-size: 15px;
	transition: 0.4s;
	pointer-events: none;
}
.column-2 .form-2 label{
	top: 25px;
	left: 20px;
	transform: translateY(-30px);
    font-size: 14px;

}
.form-1 input:focus ~ label,
.form-1 input:valid ~ label {
	transform: translateY(-30px);
	opacity: 0.8;
}
.column-1 .contactSocialMedia{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 30px 0px 0px;
}
.column-1 .contactSocialMedia a{
	width: 35px;
	height: 35px;
	text-decoration: none;
	text-align: center;
	margin-left: 15px;
	border: 1px solid transparent;
	border-radius: 5px;
	background-color: var(--primary-clr);
	transition: 0.4s;
}
.column-1 .contactSocialMedia a i{
	color: var(--bg-clr-one);
	font-size: 18px;
	line-height: 35px;
	transition: 0.4s;
}
.column-1 .contactSocialMedia a:hover{
	transform: translateY(-5px);
	background-color: var(--bg-clr-one);
	border: 1px solid var(--primary-clr);
}
.column-1 .contactSocialMedia a:hover i{
	color: var(--primary-clr);
}
.column-2 .form-2{
	width: 100%;
	height: calc(100% - 50px);
	/* padding-top: 50px; */
}
.column-2 .form-2 .inputGroup{
	position: relative;
	width: 100%;
	height: 100%;
	border-left: 1px solid var(--text-clr);
}
.column-2 .form-2 textarea{
	width: 100%;
	min-height: 25vh;
	display: block;
	resize: none;
	border: none;
	outline: none;
	background-color: var(--bg-clr-one);
	color: var(--primary-clr);
	padding: 20px;
	font-size: 16px;
}
.column-2 .form-2 .form-button{
	width: 100%;
	height: 50px;
	border: none;
	outline: none;
	background-color: var(--primary-clr);
	color: var(--bg-clr-one);
	font-size: 17px;
	letter-spacing: 1px;
	text-align: center;
	cursor: pointer;
	transition: 0.4s;
}
.column-2 .form-2 .form-button:hover{
	background-color: var(--links-clr);
}
/*   *** Contact Section Styling Ends ***   */


@media (max-width: 767px) {
    /* Contact Section */
	.contact-form .contact-row{
		flex-direction: column;
	}
	.contact-row .column-1,
	.contact-row .column-2{
		flex-basis: 100%;
	}
	.contact-row .column-1{
		min-width: 250px;
		padding: 20px;
	}
	.column-2 .form-2 textarea{
		margin-top: 0px;
		/* height: 200px; */
	}
	.column-2 .form-2 .inputGroup{
		border: 1px solid #ddd;
	}
}