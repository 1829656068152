
/*   *** About Section Styling Starts ***   */
.about{
	padding: 90px 8% 40px;
	display: flex;
	justify-content: space-between;
}
.about .about-image{
	position: relative;
	flex-basis: 30%;
	height: 400px;
	max-height: 450px;
	background-color: var(--primary-light-clr);
}
.about .about-desc{
	flex-basis: 65%;
	margin-left: 3%;
}
.about .about-image::before{
	position: absolute;
	content: '';
	width: calc(100% - 15px);
	height: calc(100% - 15px);
	outline: 3px solid var(--primary-clr);
	top: -10px;
	left: -10px;
	z-index: 1;
}
.about .about-image img{
	position: relative;
	width: 100%;
	min-width: 280px;
	height: 100%;
	object-fit: cover;
	z-index: 2;
}
.about .about-image .social-media{
	position: absolute;
	top: 50%;
	left: calc(100% - 10px);
	transform: translate(-50%, -50%);
	z-index: 3;
	background-color: var(--primary-clr);
	padding: 15px;
	border-radius: 5px;
}
.about .about-image .social-media i{
	color: var(--bg-clr-one);
	margin: 8px 0px;
	cursor: pointer;
	transition: 0.4s;
}
.about .about-image .social-media i:hover{
	transform: scale(1.2);
}
.about .about-desc h3{
	font-size: 17px;
	color: var(--links-clr);
}
.about .about-desc h2{
	margin: 10px 0px;
	font-size: 22px;
	color: var(--primary-clr);
}
.about .about-desc p{
	font-size: 15px;
	color: var(--text-clr);
}
.about-desc .about-personal-info{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px 20px;
	margin-top: 14px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--primary-clr);
}
.about-personal-info div{
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.about-personal-info div span{
	color: var(--text-clr);
	letter-spacing: 1px;
}
.about-desc .download-btn{
	border-radius: 25px;
	margin-top: 25px;
}
/*   *** About Section Styling Ends ***   */


@media (max-width: 768px) {
    
	/* About Section */
	.about{
		flex-direction: column;
	}
	.about .about-desc{
		flex-basis: 100%;
		margin-left: 0px;
		margin-top: 20px;
	}
	.about-desc .about-personal-info{
		grid-template-columns: 1fr;
		grid-gap: 10px 20px;
		margin-top: 14px;
		padding-bottom: 10px;
		border-bottom: 1px solid var(--primary-light-clr);
	}
	.about .about-image{
		flex-basis: 100%;
		min-height: 300px;
	}
	.about .about-image img{
		min-width: 250px;
		min-height: 300px;
        max-height: 450px;
	}
}