/* TextEditor.css */

:root {
  --bg-clr-one: #f6f9fe;
  --bg-clr-two: #f4f7fc;
  --bg-clr-three: #eef1f6;
  --primary-clr: #05555c;
  --primary-light-clr: #eaf3fa;
  --links-clr: #186f78;
  --text-clr: #3f7277;
  --secondary-heading: #277b6d;
}
  
.text-editor h1 {
    font-size: 36px;
    color: var(--primary-clr);
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Container for the entire editor */
  .editor-container {
    background-color: var(--bg-clr-one);
    border: 1px solid var(--primary-clr);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* width: 70%; */
    min-height: 445px;
    overflow: hidden; /* Clear floats */
    display: flex;
    flex-direction: row;
  }
  /* Post header input */
  .editor-container input[type="text"] {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  /* Quill editor container */
  .editor-container .quill-editor-container {
    float: left;
    width: 70%;
  }
  
  /* Right panel for keywords and images */
  .editor-container .right-panel {
    float: right;
    width: 30%;
    padding: 0 20px;
  }
  
  /* Keywords input */
  .editor-container input.keywords-input {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  /* Image uploader */
  .editor-container input[type="file"] {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Uploaded images */
  .editor-container .uploaded-images {
    margin-top: 10px;
  }
  
  /* Individual uploaded image */
  .editor-container .uploaded-images img {
    display: block;
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  /* Quill toolbar */
  .ql-toolbar {
    background-color: var(--bg-clr-two);
    border-bottom: 2px solid var(--primary-clr);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  /* Quill editor */
  .ql-editor {
    border: 1px solid var(--text-clr);
    min-height: 300px;
    font-family: Arial, sans-serif;
    color: var(--text-clr);
  }
  
  /* Quill headings */
  .ql-editor h1, .ql-editor h2, .ql-editor h3 {
    color: var(--secondary-heading);
  }
  
  /* Quill links */
  .ql-editor a {
    color: var(--links-clr);
  }
  
  /* Quill blockquote */
  .ql-editor blockquote {
    border-left: 4px solid var(--primary-clr);
    padding-left: 10px;
    margin-left: 0;
  }
  
  /* Quill lists */
  .ql-editor ul, .ql-editor ol {
    padding-left: 2em;
  }
  
  /* Quill ul */
  .ql-editor ul {
    list-style-type: disc;
  }
  
  /* Quill ol */
  .ql-editor ol {
    list-style-type: decimal;
  }
  
  /* Quill preformatted text */
  .ql-editor pre {
    background-color: var(--bg-clr-three);
    border-radius: 5px;
    padding: 10px;
    margin: 0;
    white-space: pre-wrap;
    font-family: Consolas, monospace;
  }
  
  /* Quill picker */
  .ql-snow .ql-picker {
    color: var(--primary-clr);
  }
  
  /* Quill stroke */
  .ql-snow .ql-stroke {
    stroke: var(--primary-clr);
  }
  
  /* Quill fill */
  .ql-snow .ql-fill {
    fill: var(--primary-clr);
  }
  
  /* Quill picker options */
  .ql-snow .ql-picker-options {
    background-color: var(--bg-clr-three);
    color: var(--primary-clr);
  }
  
  /* Quill active picker options */
  .ql-snow .ql-picker-options .ql-active {
    color: var(--bg-clr-three);
    background-color: var(--primary-clr);
  }
  
  /* Quill expanded picker label */
  .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: var(--primary-clr);
  }
  
  /* Quill expanded picker options */
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: var(--primary-clr);
  }
  

  /* TextEditor.css */

/* Right panel styles */
.editor-container .right-panel {
    float: right;
    width: 30%;
    padding: 0 20px;
  }
  
  /* Keywords input styles */
  .keywords-input {
    width:100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  /* Image uploader styles */
  .image-input {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Image previewer styles */
  .image-preview {
    display: flex;
    flex-wrap: wrap;
  }
  
  .image-item {
    width: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  /* Save button styles */
  .save-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: var(--primary-clr);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #033c41; /* Darken on hover */
  }
  


  
  @media (max-width: 768px) {
    .editor-container{
        flex-wrap: wrap;
    }

    .editor-container .right-panel {
        float: right;
        width: 100%;
        padding: 0 20px;
      }
  }