
/*   *** Resume Section Styling Starts   */
.resume{
	background-color: var(--bg-clr-one);
}
.resume .resume-row{
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.resume-row .column .sub-heading{
	color: var(--primary-clr);
	padding: 0px 0px 20px 30px;
}
.column .resume-contents{
	padding-left: 30px;
	border-left: 2px solid var(--primary-clr);
}
.resume-contents .box{
	position: relative;
	padding: 20px;
	border-bottom: 5px solid var(--bg-clr-one);
	border-radius: 5px;
	background-color: var(--primary-light-clr);
	cursor: pointer;
	transition: all 0.4s;
}
.resume-contents .box:nth-child(3){
	border-bottom: 0px;
}
.resume-contents .box::before{
	content: '';
	position: absolute;
	width: 12px;
	height: 14px;
	background-color: var(--primary-clr);
	top: 21px;
	left: -30px;
}
.resume-contents .box::after{
	content: '';
	position: absolute;
	border-width: 7px;
	border-style: solid;
	border-color: transparent transparent transparent var(--primary-clr);
	top: 21px;
	left: -18px;
}
.resume-contents .box h4{
	position: relative;
	color: var(--secondary-heading);
}
.resume-contents .box h3{
	font-size: 19px;
	padding: 10px 0px 6px;
	color: var(--primary-clr);
	margin-top: 2px;
}
.resume-contents .box p{
	margin-top: 3px;
	line-height: 1.3;
	color: var(--text-clr);
	font-size: 15px;
}
.resume-contents .box h5{
	position: relative;
	margin-top: 12px;
	right: 0;
	text-align: right;
	color: var(--secondary-heading);
	font-weight: 500;
}
/*   *** Resume Section Styling Ends ***  */

@media (max-width: 767px) {
    /* Resume Section */
	.resume .resume-row{
		flex-direction: column;
	}
	.resume-row .column-right{
		margin-top: 30px;
	}
}