/*   *** Home Section Styling Starts ***   */
.home{
	position: relative;
	width: 100%;
	min-height: 100vh;
	background-color: var(--bg-clr-one);
	display: flex;
	align-items: center;
	flex-direction: column;
}
.home .navbar{
	position: fixed;
	width: 100%;
	max-height: 100px;
	height: 13vh;
	top: 0;
	left: 0;
	padding: 0px 8%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 100;
	transition: 0.4s;
}
.home.active .navbar{
	top: 0;
	position: fixed;
	max-height: 70px;
	height: 10vh;
	background-color: var(--bg-clr-one);
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
}
.navbar .logo img{
	width: 110px;
	height: auto;
}
.navbar ul.nav-links{
	list-style: none;
}
.navbar ul.nav-links li{
	display: inline-block;
	margin: 0px 18px;
}
.navbar ul.nav-links li a{
	position: relative;
	display: inline-block;
	text-decoration: none;
	font-size: 15px;
	color: var(--links-clr);
	transition: color 0.4s;
}
.navbar ul.nav-links li a::after{
	position: absolute;
	content: '';
	width: 100%;
	height: 2px;
	top: 110%;
	left: 0;
	background-color: var(--primary-clr);
	transform: scaleX(0);
	transition: 0.4s;
}
.navbar ul.nav-links li a:hover::after{
	transform: scaleX(1);
}
button.contact-btn{
	padding: 8px 15px;
	font-size: 15px;
	border-radius: 5px;
}
.navbar .menu-btn{
	cursor: pointer;
	user-select: none;
	display: none;
}
.navbar .menu-btn .bar{
	display: block;
	width: 26px;
	height: 3px;
	background-color: var(--primary-clr);
	margin: 5px auto;
	border-radius: 5px;
	transition: all 0.3s cubic-bezier(1, 0.3, 0.3, 1);
}
.home .hero{
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding: 0px 8%;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.hero .hero-text{
	position: relative;
	top: -10px;
	z-index: 1;
}
.hero .hero-text h3{
	font-size: 20px;
	color: var(--primary-clr);
	opacity: 0.9;
}
.hero .hero-text h1{
	position: relative;
	font-size: 45px;
	color: var(--primary-clr);
	margin: 12px 0px 25px;
}
.hero .hero-text h1::after{
	content: '';
	position: absolute;
	height: 3px;
	width: 40px;
	background-color: var(--primary-clr);
	left: 0;
	top: 110%;
}
.hero .hero-text h2{
	font-size: 22px;
	letter-spacing: 1px;
	color: var(--primary-clr);
}
.hero .hero-text p{
	max-width: 500px;
	color: var(--links-clr);
	margin: 15px 0px 35px;
	line-height: 1.2;
}
.hero .hero-text .hire-btn{
	padding: 10px 35px;
	box-shadow: 0px 20px 24px 3px rgba(5,85,92,0.2);
}
.hero .hero-text .hire-btn:hover{
	box-shadow: 0px 5px 15px 3px rgba(5,85,92,0.2);
}
.hero .hero-image{
	position: absolute;
	width: 500px;
	height: 900px;
	background-color: var(--primary-light-clr);
	border-bottom-left-radius: 250px;
	border-bottom-right-radius: 250px;
	transform: rotate(40deg);
	right: -30px;
	top: -220px;
	overflow: hidden;
	z-index: 0;
}
.hero .hero-image img{
	position: absolute;
	width: auto;
	height: auto;
	object-fit: cover;
	transform: rotate(-40deg);
	left: 20px;
	bottom: 0px;
}
.home .career-container{
	position: relative;
	width: 100%;
	height: auto;
	margin-top: -100px;
	left: 0;
	padding: 0px 8% 10px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 15px 2%;
}
.career-container .career-item{
	background-color: var(--bg-clr-one);
	height: 70px;
	display: flex;
	align-items: center;
	padding: 10px 15px;
	border-radius: 5px;
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
}
.career-container .career-item .icon{
	display: inline-block;
	background-color: var(--primary-clr);
	min-width: 50px;
	width: 50px;
	height: 50px;
	margin-right: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.career-container .career-item .icon i{
	font-size: 22px;
	color: var(--bg-clr-one);
}
.career-container .career-item .career-desc{
	display: inline-block;
}
.career-item .career-desc h2{
	font-size: 20px;
	line-height: 1.4;
	color: var(--primary-clr);
}
.career-item .career-desc p{
	font-size: 14px;
	color: var(--text-clr);
}
/*   *** Home Section Styling Ends ***   */




@media (max-width: 1100px) {
    /* Home Section */
	.navbar ul.nav-links li{
		margin: 0px 16px;
	}
}

@media (max-width: 768px) {
    
	/* Home Section */
	.navbar .menu-btn{
		display: block;
		margin-left: 30px;
		z-index: 101;
	}
	.navbar .menu-btn.active .bar:nth-child(1){
		transform: translateY(8px) rotate(45deg);
	}
	.navbar .menu-btn.active .bar:nth-child(3){
		transform: translateY(-8px) rotate(-45deg);
	}
	.navbar .menu-btn.active .bar:nth-child(2){
		transform: translateX(-30px);
		opacity: 0;
	}

	.navbar ul.nav-links{
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		/*right: 0;*/
		right: -100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: var(--primary-light-clr);
		transition: right 0.3s cubic-bezier(1, 0.3, 0.3, 1);
		z-index: 100;
	}
	.navbar ul.nav-links.active{
		right: 0;
	}
	.navbar ul.nav-links li{
		margin: 25px 0px 0px;
	}
	.navbar ul.nav-links li:nth-child(1){
		margin-top: 180px;
	}
	.navbar ul.nav-links li a{
		font-size: 18px;
		letter-spacing: 2px;
	}
	.navbar .button-wrapper{
		margin-left: auto;
	}
	.hero .hero-image img{
		opacity: 0.3;
	}
	.home .career-container{
		grid-template-columns: 1fr;
	}
}

@media (max-width: 550px) {
    /* Home Section */
	.hero .hero-image{
		right: -200px;
	}
}

/*   === Screen Size 350px or Smaller  ===   */
@media(max-width: 350px){
	button.contact-btn{
		display: none;
	}
}

/*   *** Media Query Ends ***   */