body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif; 
  }
  
  .modal {
    max-width: 500px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin: 0 auto 20px;
    position: relative;
    cursor: pointer;
  }
  
  .profile-pic .dashes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed #aaa;
    border-radius: 50%;
  }
  
  .profile-pic label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
    cursor: pointer;
  }
  
  .has-image .dashes {
    display: none;
  }
  .has-image label {
    color: transparent;
  }
  
   .submit-btn {
    background: #0095ff;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 12px 15px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 20px;
  }
  
  .submit-btn:hover {
    background: #0070e0;
  }
  .input-field {
    width: calc(50% - 10px);
    padding: 10px;
    /* margin-bottom: 15px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    border-color: #ccc;
    margin: 0 5px 15px;
    /* border-radius: 15px; */
  }

  .input-field:nth-child(4){
    width: calc(100% - 10px);
  }

  
  .input-field:focus {
    outline: none;
    border-color: #0095ff;
  }
    