    .posts-page {
    display: flex;
    justify-content: center; /* Center horizontally */
    margin-top: 50px; /* Adjust margin as needed */
    width: 60%;
    margin: 50px auto;
    }

    .posts-container {
    width: 100%; /* 60% width for the container */
    padding: 0;
    background-color: #fff; /* Adjust background color as needed */
    border-radius: 10px;
    /* margin-left: 20px; Add some spacing between menu and container */
    }

    
    .menu {
      width: 50%; /* 30% width for the menu */
      padding: 15px;
      margin-left: 20px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 10px;
      height: 100%;
    }
    
    .menu h2 {
      font-size: 24px;
      font-weight: bold;
      color: #333;
      margin-bottom: 20px;
      text-transform: uppercase; /* Convert text to uppercase */
      border-bottom: 2px solid var(--text-clr); /* Add a bottom border */
      padding-bottom: 5px; /* Add some space between text and border */
    }
    
    
    .last-posts-list {
      list-style-type: none;
      padding: 0;
    }
    
    .last-posts-list li {
      margin-bottom: 10px;
    }
    
      .last-posts-list li a {
        text-decoration: none;
        color: #333;
        display: block;
        padding: 10px;
        border-radius: 5px;
        transition: background-color 0.3s ease; /* Smooth transition effect */
      }
      
      .last-posts-list li a:hover {
        background-color: #f0f0f0; /* Change background color on hover */
      }
      
      .keywords {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
      }
      
      .keyword {
        padding: 8px 16px;
        background-color:darkcyan;
        color: #fff;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 20px;
        transition: background-color 0.3s ease; /* Smooth transition effect */
        cursor: pointer;
      }
      
      .keyword:hover {
        background-color: var(--primary-clr); /* Change background color on hover */
      }
      
      
      .menu .search-container {
        margin: 20px auto;
        display: flex;
        gap: 10px;
      }
      
      .menu .search-container input[type="text"] {
        width: 70%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
      }
      
      .menu .search-container button {
        font-size: 14px;
        padding: 10px 20px;
        background-color: var(--links-clr);
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
      }
      
      .search-container button:hover {
        background-color: var(--primary-clr);
      }
      
      
      @media (max-width: 1200px) {
        .posts-page {
          width: 80%;
        }
      }
      
      @media (max-width: 1000px) {
        .posts-page {
          width: 90%;
        }
      }
      
      @media (max-width: 800px) {
        .posts-page {
          width: 100%;
        }
      }
      
      @media (max-width: 768px) {
        .posts-container{
          width: 100%;
        }
        .posts-page {
          flex-direction: column;
          gap: 2em;
          width: 100%;
          padding: 0;
          margin-left: -3px; 
          align-items: center;
        }
      
        .posts-container {
          width: 100%; 
          padding: 0;
          margin: 0;
          
        }

        .posts-container .pagination {
          display: flex;
          flex-wrap: wrap;
          gap: 0;
          width: 20em;
        }
      
        .menu {
          width: 100%;
          margin-left: 0;
          margin-top: 20px; 
          border: none; 
        }

        .menu .search-container {
            margin: 20px 0 ;
        }
      
        .menu h2 {
          margin-top: 0; 
        }
      }
      
      

    