.dashboard {
    display: flex;
  }
  
  .sidebar {
    background-color: #333;
    color: #fff;
    padding: 20px;
    width: 200px;
  }
  
  .sidebar h2 {
    margin-bottom: 20px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
  }
  
  .cards {
    display: flex;
    justify-content: space-between;
  }
  
  .card {
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 20px;
    flex: 1;
    margin-right: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .card:last-child {
    margin-right: 0;
  }
  