/* General Styles */
.post_details .post{
  width: 60%;
  margin: 0 auto 12px;
}




.post_details .comment-section {
  max-width: 60%;
  margin: 0 auto;
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.post_details .comment-header {
  text-align: center;
  margin-bottom: 20px;
}

/* Comment Form Styles */
.post_details .comment-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.post_details .comment-input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.post_details .comment-form-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post_details .comment-submit {
  /* width: 100%; */
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

/* Comments List Styles */
.post_details .comments {
  margin-bottom: 20px;
}

.post_details .comment {
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid #eee;
}

.post_details .comment-content {
  /* display: flex; */
  align-items: center;
  margin-bottom: 10px;
}

.post_details .comment-author {
  font-weight: bold;
  margin-right: 10px;
}

.post_details .comment-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.post_details .reply-button {
  margin-top: 15px;
  margin-left: 5px;
  padding: 2px 5px;
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

/* Pagination Styles */
.post_details .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.post_details .pagination-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin: 0 5px;
}

.post_details .pagination-info {
  font-size: 14px;
  margin: 0 10px;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce-animation {
  0% {
    transform: translate(-50%, -50%) scaleY(1);
  }
  50% {
    transform: translate(-50%, -50%) scaleY(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scaleY(1);
  }
}


@media (max-width: 767px) {
  .post_details .post{
    width: 100%;
    padding: 15px;
    border: none;
    box-shadow: none;
  }



  .post_details .comment-section{
    max-width: 100%;
    /* background-color: red; */
  }

  .post_details  .comment-section .comment-form {
    align-items: center;
  }
  .post_details  .comment-section .comment-form-content {
    width: 100%;
    flex-direction: column;
    justify-items: center;
  }


  .post_details  .comment-section textarea,
  .post_details  .comment-section .comment-form-content input,
  .post_details  .comment-section .comment-form-content button{
    width: 100%;
  }
}