.post {
  width: 100%;
  margin: 0 auto;
}

  .post .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.post .top .userDeatils{
    display: flex;
    align-items: center;
}
.post .top .userDeatils .profileImg{
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
    overflow: hidden;
}
.post .top .userDeatils h3{
    font-size: 18px;
    color: #4d4d4f;
    font-weight: 700;
    line-height: 1rem;
    cursor: pointer;
}
.post .top .userDeatils span{
    font-size: 0.75em;
}
.cover{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

  .post {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .post img {
    max-width: 100%;
  }
  
  .post-image {
    width: 100%;
    max-height: 400px; /* Adjust as needed */
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 0;
  }
  

.btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btns img{
    max-width: 24px;
    cursor: pointer;
}
.btns .left img{
    margin-right: 8px;
}
.postTime{
    margin-top: 10px;
    font-weight: 500;
    color: #777;
}
.keywords {
  display: flex;
  flex-wrap: wrap;
}
.keywords .postKeywords{
    font-size: 16px;
    font-weight: 500;
    background-color: var(--primary-clr);
    color: aliceblue;
    margin: 4px 4px 0 0;
    padding: 4px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: var(--primary-clr);
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .pagination button:hover {
    background-color: var(--links-clr);
    transform: translateY(-2px);
  }
  
  .pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination .currentPage {
    background-color: var(--primary-light-clr);
  }
  
  .pagination .ellipsis {
    font-size: 20px;
    margin: 0 10px;
    color: var(--text-clr);
  }
  
  .pagination .arrow {
    font-size: 20px;
    color: var(--text-clr);
  }