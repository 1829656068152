.statistics-sidebar {
    /* Your styles for the sidebar container */
}

.charts-container {
    display: flex;
    flex-wrap: wrap;
}

.chart {
    flex: 1 0 100%; /* Each chart takes full width by default */
    margin-bottom: 20px; /* Add some margin between charts */
}

.chart > div {
    background-color: #f4f4f4; /* Set a background color for each chart */
    border-radius: 10px; /* Add rounded corners */
    padding: 20px; /* Add padding to the chart container */
    height: 300px; /* Set a fixed height for each chart */
}

.chart h4 {
    margin-top: 0;
}

@media (min-width: 768px) {
    /* For tablet screens, arrange two charts side by side */
    .chart {
        flex: 1 0 50%; /* Each chart takes 50% width */
    }
    
    .chart > div {
        height: 350px; /* Increase height for tablets */
    }
}

@media (min-width: 1024px) {
    /* For larger screens like computers, arrange three charts side by side */
    .chart {
        flex: 1 0 33.33%; /* Each chart takes 33.33% width */
    }
    
    .chart > div {
        height: 400px; /* Increase height for computers */
    }
}
