
/*   *** Services Section Styling Starts   */
.services .services-container{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 10px;
}
.services-container .service-box{
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-basis: 32%;
	padding: 40px 40px 30px;
	background-color: var(--bg-clr-one);
	border: 1px solid var(--bg-clr-three);
	border-radius: 5px;
	margin-bottom: 20px;
	overflow: hidden;
	cursor: pointer;
	transition: 0.4s;
	z-index: 1;
}
.service-box .icon-wrapper{
	position: relative;
	background-color: var(--primary-clr);
	font-size: 30px;
	width: 55px;
	height: 55px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	transition: 0.3s;
	z-index: 3;
}
.service-box .icon-wrapper i{
	font-size: 24px;
	color: var(--bg-clr-one);
	transition: 0.3s;
}
.service-box h2{
	padding: 15px 0px 10px;
	color: var(--primary-clr);
	font-size: 20px;
	transition: 0.3s;
}
.service-box p{
	color: var(--text-clr);
	line-height: 1.3;
	transition: 0.3s;
}
.service-box h3{
	font-size: 16px;
	color: var(--links-clr);
	margin-top: 50px;
}
.services-container .service-box::after{
	position: absolute;
	content: '';
	width: 100%;
	height: 1.3%;
	background-color: var(--primary-clr);
	left: 0%;
	bottom: 0%;
	z-index: -1;
	transition: all 0.4s cubic-bezier(0.69, 0.05, 0, 0.97);
}
.services-container .service-box:hover::after{
	height: 100%;
}
.services-container .service-box:hover{
	transform: translateY(-2px);
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
}
.services-container .service-box:hover.service-box .icon-wrapper{
	background-color: var(--bg-clr-one);
}
.services-container .service-box:hover.service-box .icon-wrapper i{
	color: var(--primary-clr);
}
.services-container .service-box:hover.service-box h2{
	color: var(--bg-clr-one);
}
.services-container .service-box:hover.service-box p{
	color: #aaa;
}
.services-container .service-box:hover.service-box h3{
	color: #e3e3e8;
}
/*   *** Services Section Styling Ends   */


@media (max-width: 1100px) {
    
	/* Services Section */
	.services-container .service-box{
		flex-basis: 48%;
	}
}

@media (max-width: 768px) {
	/* Services Section */
	.services-container .service-box{
		flex-basis: 100%;
		margin-bottom: 10px;
	}
}