*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}
html{
	scroll-behavior: smooth;
	
	/*   *** Storing Colors In Variables ***   */
	--bg-clr-one: #f6f9fe;
	--bg-clr-two: #f4f7fc;
	--bg-clr-three: #eef1f6;
	--primary-clr: #05555c;
	--primary-light-clr: #eaf3fa;
	--links-clr: #186f78;
	--text-clr: #3f7277;
	--secondary-heading: #277b6d;
}

/*   *** Reusable Code Starts ***   */
button.btn{
	background-color: var(--primary-clr);
	color: var(--bg-clr-one);
	border: 1px solid transparent;
	border-radius: 25px;
	font-size: 16px;
	padding: 10px 18px;
	letter-spacing: 1px;
	cursor: pointer;
	transition: 0.4s;
}
button.btn:hover{
	background-color: var(--bg-clr-one);
	color: var(--primary-clr);
	border: 1px solid var(--primary-clr);
}

section.reusable{
	width: 100%;
	padding: 70px 8% 50px;
}
section.reusable .headings{
	max-width: 700px;
	margin: 0px auto 45px;
	text-align: center;
}
section.reusable .headings h3{
	font-size: 17px;
	color: var(--links-clr);
}
section.reusable .headings h1{
	position: relative;
	font-size: 32px;
	margin: 10px 0px 15px;
	color: var(--primary-clr);
}
section.reusable .headings p{
	font-size: 15px;
	color: var(--text-clr);
}
/*   *** Reusable Code Ends ***   */

body{
	width: 100%;
	min-height: 100vh;
}
.Website-container{
	position: relative;
	max-width: 1500px;
	margin: 0px auto;
}