:root {
    --bg-clr-one: #f6f9fe;
    --bg-clr-two: #f4f7fc;
    --bg-clr-three: #eef1f6;
    --primary-clr: #05555c;
    --primary-light-clr: #eaf3fa;
    --links-clr: #186f78;
    --text-clr: #3f7277;
    --secondary-heading: #277b6d;
  }
  
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: var(--bg-clr-one);
  }
  
  .portfolio {
    padding: 50px;
    background-color: var(--bg-clr-two);
  }
  
  .section-heading {
    text-align: center;
    color: var(--primary-clr); 
  }
  
  /* Grid and work item styles */
  .work-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 90%;
    margin: 10px auto;
  }
  
  .work {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-clr-three);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .work-image {
    height: 200px;
    overflow: hidden;
    border-radius: 10px 10px 0 0; 
  }
  
  .work-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Work details */
  .work-details {
    padding: 20px;
  }
  
  .work-title {
    margin-top: 10px;
    color: var(--primary-clr);
    font-size: 1.5rem;
  }
  
  .work-description {
    color: var(--text-clr);
    font-size: 1.1rem;
    line-height: 1.4;
  }
  
  .work-link {
    margin-top: auto; 
    position: relative;
    padding: 10px 20px;
    border: 2px solid var(--primary-clr);
    color: var(--primary-clr);  
    text-decoration: none;
    border-radius: 2px;
    font-weight: 600;
    overflow: hidden;
    z-index: 1;
  }
  
  .work-link::after {
    content: "";
    position: absolute;
    top: 12px; left: 12px; bottom: 0;
    width: 100%;
    height: 35%;
    background-color: var(--primary-clr);
    z-index: -1;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  
  .work-link:hover::after {
    height: 100%;
    top: 2px; left: 2px;
    transform: translateX(0);
  }
  
  .work-link:hover {
    color: #fff;
  }