.sidebar {
  width: 50px; /* Initial collapsed width */
  max-width: 50px; /* Ensure it doesn't exceed this width */
  height: 100vh;
  background-color: var(--primary-clr);
  position: relative; /* Fix to viewport */
  transition: width 0.3s ease-in-out; /* Smooth transition */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden; /* Hide overflow */
  z-index: 1000; /* Ensure it's on top */
}

.sidebar.open {
  width: 200px; /* Expanded width */
  max-width: 200px; /* Ensure it doesn't exceed this width */
}

.sidebar .toggle-btn {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--links-clr);
  color: #fff;
  border: none;
  padding: 25px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, width 0.3s ease;
}

.sidebar .toggle-btn:hover {
  background-color: var(--text-clr);
}

.sidebar .toggle-btn p {
  margin-left: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar .toggle-btn img {
  position: absolute;
  height: 36px;
  width: 36px;
  top: 7px;
  left: 7px;
  border-radius: 50%;
  border: 2px solid var(--primary-light-clr);
}

.sidebar nav {
  margin-top: 20px;
  width: 100%;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  text-decoration: none;
  color: var(--primary-light-clr);
  display: flex;
  padding: 10px;
  align-items: center;
  transition: background-color 0.3s ease;
  width: 100%;
}

.sidebar a:hover {
  background-color: var(--text-clr);
  color: #fff;
}

.sidebar a:focus {
  outline: none;
  box-shadow: 0 0 5px var(--text-clr);
}

.sidebar .icon {
  margin-right: 15px;
  font-size: 24px;
}

.sidebar .icon-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sidebar .icon-list .icon {
  margin-bottom: 15px;
  color: var(--primary-light-clr);
  transition: color 0.3s ease;
}

.sidebar .icon-list .icon:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .sidebar {
    width: 0;
    max-width: 0;
    overflow-x: hidden; /* Hide horizontal overflow */
  }
  
  .sidebar.open {
    width: 100%;
    max-width: 100%; /* Expand to full width on small screens */
    position: absolute;
  }
}
