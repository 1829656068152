.login-form {
    max-width: 500px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .login-form h1 {
    font-size: 32px;
    margin: 15px auto 20px;
    text-align: center;
}


.login-form input {
    margin: 10px auto;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    padding: 10px;
    
  }

.login-form .submit-btn {
  background: #0095ff;
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 12px 15px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
}

.login-form .submit-btn:hover {
  background: #0070e0;
}